import React, { useState } from 'react';
import styled from '@emotion/styled';
// import core components
import { InputGroup } from 'components';
import GoogleAutoComplete from 'components/auto-complete/GoogleAutoComplete';
import CloseIcon from '/static/icons/controls/close.svg'
import { CloseModalButton } from 'components/Login/styles';
import { Button } from 'components/Login/styles';
import { addressService } from 'services';
// import static assets
import LoadingGif from '/static/gifs/loading.gif';

const Wrapper = styled.div`
    position: absolute;
    width: 450px;
    min-height: 350px;
    background: ${({theme}) => theme.colors.white};
    border-radius: ${({theme}) => theme.borderRadius.round};
    padding: 15px 30px 30px 30px;
`;
const AddressForm = styled.form`
    margin: 0px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 38px;
    }
    > div {
        display: flex;
        flex-direction: column;
        border: none;
    }
    input[type='submit'], input[type='button'] {
        outline: none;
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeight.button};
    }
    .addressFields {
        display: flex;
        flex-wrap: wrap;
        .address-field {
            width: calc(50% - 10px);
            &:nth-of-type(even) {
                margin-left: 20px;
            }
        }
    }
    .row {
        justify-content: space-between;
        .addressInput {
            width: 40%;
        }
        .addressCity {
            width: 55%;
        }
        .addressState {
            width: 25%;
        }
        .addressPostcode {
            width: 25%;
        }
        .addressCountry {
            width: 45%;
        }
    }
`;

const FieldSet = styled.div`
    margin-top: 10px;
    font-size: 14px;
    > div.fieldset {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    input[type='text'], input[type='search'] {
        height: 45px;
        border: 1px solid ${props => props.theme.colors.stroke};
        &.required {
            border: 1px solid ${props => props.theme.colors.error};
        }
        box-sizing: border-box;
        border-radius: ${props => props.theme.borderRadius.round};
        padding: 0px 6px 0px 6px;
        outline: none;
        width: 100%;
        margin-bottom: 10px;
    }
    h3 {
        opacity: 0.5;
        font-size: 14px;
        font-weight: normal;
    }
`;

const Address = ({ initial, hideModal }) => {
    const [address, setAddress] = useState({
        ...initial,
        fullName: initial.addressName,
        phone: initial.addressPhoneNumber,
        company: initial.addressCompany,
        address1: initial.addressLine1,
        postcode: initial.addressPostcode,
        state: initial.addressPhoneNumber,
        country: initial.addressCountry,
        address2: initial.addressLine2,
        locality: initial.addressCity,
    });
    const [bSubmitted, setBSubmitted] = useState(false);
    const [bLoading, setBLoading] = useState(false);
    const handleChange = ev => {
        setAddress({...address, [ev.target.name]: ev.target.value})
    }
    const handleSubmit = async ev => {
        ev.preventDefault();
        
        setBSubmitted(true);
        setBLoading(true);
        try {
            await addressService.updateAddress(initial.id, address );
            handleClose();
        } catch (error) {
            ////console.log(error);
        }
        setBLoading(false);
    }
    return (
        <Wrapper>
            <CloseModalButton onClick={hideModal}>
                <CloseIcon />
            </CloseModalButton>
            <AddressForm onSubmit={handleSubmit}>
                <FieldSet>
                    <div className="fieldset">
                        <label>Name</label>
                        <InputGroup
                            name={"fullName"}
                            value={address.fullName}
                            handleChange={handleChange}
                            required={bSubmitted}
                        />
                    </div>
                    {/* <div className="fieldset">
                        <label>Relationship</label>
                        <input 
                            name={"relationship"}
                            value={address.relationship}
                            onChange={(ev) => setaddress({...address, relationship: ev.target.value})}
                            type="text"
                        />
                    </div> */}
                    <div className={"fieldset"}>
                        <label>Phone Number</label>
                        <InputGroup
                            name={"phone"}
                            value={address.phone}
                            handleChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Company</label>
                        <InputGroup
                            name={"company"}
                            value={address.company}
                            handleChange={handleChange}
                            required={false}
                        />
                    </div>
                    <div className="fieldset">
                        <label htmlFor="address1">Deliver to</label>
                        <GoogleAutoComplete
                            apiKey={process.env.GATSBY_GOOGLE_MAPS_API}
                            placeholder="Start typing a location..."
                            deliveryAddress={address}
                            setDeliveryAddress={setAddress}
                            required={bSubmitted}
                        />
                    </div>
                    <Button type="submit" value="Update" />
                </FieldSet>
            </AddressForm>
        </Wrapper>
    )
}

export default Address;