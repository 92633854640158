import styled from '@emotion/styled';

const ResponsiveTable = styled.div`
    overflow-x: auto;
    max-width: 100vw;
    table {
        width: 100%;
        td, th {
            text-align: center;
            border-bottom: 1px solid ${({theme}) => theme.colors.stroke};
            padding: 16px;
            svg {
                cursor: pointer;
            }
            white-space: nowrap;
            img {
                box-shadow: ${({theme}) => theme.shadow.card}
            }
        }
    }
    
    @media (max-width: ${props => props.theme.breakpoints.s}) {
        table {
            th, td {
                padding: 4px 8px;
                font-size: 14px;
            }
        }
    }
`;

export default ResponsiveTable;