import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
// import css
import '/static/libs/fonts.css';
import LoadingGif from '/static/gifs/loading.gif';
import * as fflate from 'fflate';
import { readTextFile } from '../../utils/file';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 500px;
  zoom: 0.3;
  & > div {
    width: 500px !important;
    height: 650px !important;
    box-shadow: rgb(0 0 0 / 7%) 0 4px 44px;
    & > canvas {
      z-index: -1;
    }
  }

  .loading-gif {
    position: absolute;
  }

  canvas {
    box-shadow: none;
    box-sizing: border-box;
  }

  .canvas-wrapper {
    outline: none;
  }
`;
const DEFAULT_BG_COLOR = '#fff';
class ItemPreviewCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageButtons: [], // buttons to set background-image
      isLoading: false, // flag to show loading gif
      bTextEditing: false, // flag to show/hide text toolbar
      currentPosition: { left: 0, top: 0 },
    };
    this.canvas = null; // canvas object
    this.hasGrid = false; // boolean to show/hide grid
    this.activeTextBox = null;
    this.loadFromJson = this.loadFromJson.bind(this);
    this.setLoadingStatus = this.setLoadingStatus.bind(this);
  }

  componentDidMount() {
    const { fabricJsonFront } = this.props;
    const fabric = window.fabric;
    // initialize the canvas state with ref
    this.canvas = new fabric.Canvas(this.c, {
      selection: false,
      preserveObjectStacking: true,
    });

    if (fabricJsonFront) {
      //console.log("fabricJsonFront: ",fabricJsonFront)
      readTextFile(
        `${process.env.GATSBY_S3_DESIGN_BUCKET_BASE_URL}/${fabricJsonFront}`,
        text => loadCanvas('outlay', text)
      )
        .then(outlayData => {
          if (outlayData?.includes('version')) {
            // check unzipped versions of json
            this.loadFromJson(JSON.parse(JSON.parse(outlayData)));
          } else {
            const compressed = new Uint8Array(outlayData.split(','));
            const decompressed = fflate.decompressSync(compressed);
            const origText = fflate.strFromU8(decompressed);
            const outlay = JSON.parse(JSON.parse(origText));
            this.loadFromJson(outlay);
          }
        })
        .catch(err => {
          //console.log('error', err);
        });
    }
  }

  loadFromJson(json, rw = true) {
    const canvas = this.canvas;
    canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), function(
      o,
      object
    ) {
      object.set('selectable', rw);
    });
  }

  setLoadingStatus(b) {
    this.setState({ isLoading: b });
  }

  render() {
    const { id } = this.props;
    const fabricScript = require('/static/libs/fabric.min.js');
    return (
      <Wrapper>
        <canvas ref={c => (this.c = c)} id={id} width={932} height={1332} />
        {this.state.isLoading && (
          <img
            className="loading-gif large"
            src={LoadingGif}
            alt="image loading"
          />
        )}
      </Wrapper>
    );
  }
}

ItemPreviewCanvas.propTypes = {
  id: PropTypes.string,
  objects: PropTypes.array,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  overlayImage: PropTypes.string,
  hasGrid: PropTypes.bool,
  zoom: PropTypes.number,
};
ItemPreviewCanvas.defaultProps = {
  id: 'canvas',
  objects: [],
  hasGrid: false,
  zoom: 1.0,
  bgColor: DEFAULT_BG_COLOR,
};
export default ItemPreviewCanvas;
