import React from 'react';
import styled from '@emotion/styled';

import { myAccountConstants } from '../../../constants';

// import static icons and images
import LoadingTitle from './LoadingTitle';
import Book2Icon from "/static/icons/my-account/book2.svg";
import FolderIcon from "/static/icons/my-account/folder.svg";
import ShoppingBag2Icon from '/static/icons/my-account/shopping-bag2.svg';
import BannersJpg from '/static/images/my-account/banners.jpg';

const Wrapper = styled.div`
  flex: 1;
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
  ul {
    margin-left: 0px;
  }
  p, img {
    margin-bottom: 0px;
  }
  .container_grid {
    flex: 3;
  }
  .container_grid__paragraph {
    margin-bottom: 26px;
  }

  /* Container Boxes */
  .container__boxes {
    min-height: 150px;
    max-width: 770px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 49px;
    justify-content: space-between;
  }

  .item__box {
    height: 150px;
    width: 170px;
    background: ${({theme}) => theme.colors.lightBlue};
    border-radius: 10px;
    text-align: center;
    margin-bottom: 10px;
    > svg {
      width: 100%;
      height: 100px;
    }
    &:hover {
      color: ${({theme}) => theme.colors.blue};
    }
  }

  .item__box:hover {
    cursor: pointer;
  }

  .item__box .item__box-icon {
    width: 100%;
  }

  .item__box:not(:last-child) {
    margin-right: 25px;
  }

  .item__box-name {
    margin-top: 6px;
    font-weight: 600;
  }

  /* Container Promotions */
  .container__promotions {
    max-width: 100%;
  }

  .container__promotions .promotions__banner {
    height: auto;
    width: 100%;
    transform: translate(-30px, 0px);
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

const Dashboard = ({setCurrentTab}) => {
  return (
    <Wrapper>
      <div className="container_grid">
        <LoadingTitle title="Welcome to your account!" bLoading={null} />
        <p className="container_grid__paragraph">
          From your account dashboard you can view your recent orders, manage
          your shipping and billing addresses, and edit your password and
          account details.
        </p>

        <div className="container__boxes">
          <div className="item__box" onClick={ev => setCurrentTab(myAccountConstants.ORDERS)}>
            <ShoppingBag2Icon />
            <p className="item__box-name">Orders</p>
          </div>
          <div className="item__box" onClick={ev => setCurrentTab(myAccountConstants.ADDRESS_BOOK)}>
            <Book2Icon />
            <p className="item__box-name">Address Book</p>
          </div>
          {/* <div className="item__box">
            <User2Icon />
            <p className="item__box-name">User Settings</p>
          </div> */}
          <div className="item__box" onClick={ev => setCurrentTab(myAccountConstants.SAVED_DESIGNS)}>
            <FolderIcon />
            <p className="item__box-name">Saved Designs</p>
          </div>
        </div>
        <div className="container__promotions">
          <h3 className="promotions__title">Promotions</h3>
          <img src={BannersJpg} alt="" className="promotions__banner" />
        </div>
      </div>
    </Wrapper>
  )
};

export default Dashboard;
