import React from 'react';
import PropTypes from 'prop-types';

import {
    Wrapper,
} from './styles';

const LineItem = ({ lineItem, as}) => {
    console.log("LINEITEM", lineItem);
    return (
        <Wrapper as={as}>
            <div className="snipcart__item__line__product">
                <div className="order-details">
                    <div className="snipcart__item__line__header">
                        <div className="order-details__header">
                            <h2 className="snipcart__item__line__header__title title">
                                { lineItem.name }
                            </h2>
                            <p><i>{lineItem?.orderStatus}</i></p>
                        </div>
                    </div>
                    <div className="order-details__container">
                        <div className="container__details">
                        <div className="cursomerDetails">
                            <img src={lineItem.metadata.thumbnail} className="front-thumbnail" alt="thumbnail of card" />
                            <div>
                                <h3 className="subtitle">Delivery options</h3>
                                <div className="clientPersonalInfo">
                                    <p>{lineItem.metadata.user.fullName} <br /><br /></p>
                                    {lineItem.metadata.user.phone && <p>{lineItem.metadata.user.phone} <br /></p>}
                                    <p>{lineItem.metadata.user.address1} <br /></p>
                                    {lineItem.metadata.user.address2 && <p>{lineItem.metadata.user.address2} <br /></p>}
                                    <p>{lineItem.metadata.user.locality} <br /></p>
                                    {lineItem.metadata.user.state && <p>{lineItem.metadata.user.state} &nbsp;</p>}
                                    <p>{lineItem.metadata.user.postcode}<br /></p>
                                    <p>{lineItem.metadata.user.country}</p>
                                </div>
                            </div>
                            <div className="clientDelivery">
                                <h3 className="subtitle">Shipping options</h3>
                                <div className="deliveryType">
                                    <img
                                        src={lineItem.metadata.delivery.src}
                                        alt="delivery option"
                                        className="deliveryIcon"
                                    />
                                    <p className="deliveryType-name">{lineItem.metadata.delivery.option}</p>
                                </div>
                                <p className="deliveryTime">
                                    Dispatch: {lineItem.metadata.delivery.dispatchDate} <br />
                                    Delivery: {lineItem.metadata.delivery.deliveryDate} <br />
                                    {lineItem.metadata.delivery.price}
                                </p>
                            </div>
                            {lineItem.metadata.offer.title && <div className="offer">
                                <h3 className="subtitle">Offer</h3>
                                <div className="offer-content">
                                    <img
                                        src={lineItem.metadata.offer.src}
                                        alt="offer option"
                                        className="detail-img"
                                    />
                                    <div className="offer-type">
                                        <p className="offer-title">{lineItem.metadata.offer.title}</p>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="product__details">
                            <div className="detail-1 detail">
                            <img
                                src={lineItem.metadata.cardSize.src}
                                alt=""
                                className="detail-img"
                            />
                            <p className="detail-name">{lineItem.metadata.cardSize.name}</p>
                            </div>
                            <div className="detail-2 detail">
                            <img
                                src={lineItem.metadata.envelopeType.src}
                                className="detail-img"
                            />
                            <p className="detail-name">{lineItem.metadata.envelopeType.name}</p>
                            </div>
                            <div className="detail-3 detail">
                            <img
                                src={lineItem.metadata.envelopeColor.src}
                                className="detail-img"
                            />
                            <p className="detail-name">{lineItem.metadata.envelopeColor.name}</p>
                            </div>

                            <p className="singlePrice">{lineItem.metadata.price.unit}</p>

                            <div className="quantity">
                                <p className="quantity-title">Quantity</p>
                                <p className="quantity-amout">{lineItem.metadata.price.quantity}</p>
                            </div>

                            <p className="totalBalance">{lineItem.metadata.price.total}</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
LineItem.PropTypes = {
    as: PropTypes.string
}
LineItem.defaultProps = {
    as: 'div'
}
export default LineItem;
