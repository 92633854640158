import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from 'contexts';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import { Link } from 'gatsby';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import core components
import { designsService } from 'services';
import LoadingTitle from './LoadingTitle';
import { ROWS_DESIGNS } from '/config/pagination';
import { toShortDate } from 'utils/moment';
import ResponsiveTable from './ResponsiveTable';
import TooltipIcon from './TooltipIcon';
import * as fflate from 'fflate';
import { readTextFile } from 'utils/file';
import ItemPreviewCanvas from '../../ItemPreviewCanvas';
import Canvas from '../workflow/canvas';
const slugHelper = require('helpers/gatsbyHelper');

const Wrapper = styled.div`
  table {
    td {
      height: 220px;
    }
    td .saved-design-thumbnail {
      height: 100%;
      max-width: 119px;
    }
    td.warning {
      a {
        margin-right: 10px;
      }
    }
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  background-color: ${({ theme }) => theme.colors.stroke};
  ul.pagination {
    display: inline-block;
    margin: 0px;
    margin-left: -5px;
  }
  ul.pagination li {
    cursor: pointer;
    cursor: pointer;
    padding: 3px;
    margin: 0px;
    margin-right: 5px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.black};
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
    &.active {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.blue};
    }
    &.disabled {
      visibility: hidden;
    }
    > a {
      font-weight: 600 !important;
    }
  }
`;
const SavedDesigns = ({ user, handleBack }) => {
  const [designs, setDesigns] = useState([]);
  const [bLoading, setBLoading] = useState(false);
  const [page, setPage] = useState(0);
  const { createNotification } = useContext(UserContext); // Context API to get user state
  const handlePageClick = data => {
    setPage(data.selected);
  };

  const loadData = async () => {
    setBLoading(true);
    try {
      // fetch designs
      let arr = (await designsService.getDesigns(user.cognitoUserId)) || [];
      //console.log(arr,"ARRAY");
      setDesigns(
        arr.sort(function(a, b) {
          return toShortDate(b.createdAt).localeCompare(
            toShortDate(a.createdAt)
          );
        })
      );
    } catch (error) {
      ////console.log(error);
    }
    setBLoading(false);
  };
  const handleRemove = async design => {
    if (window.confirm('Do you want to continue?')) {
      setBLoading(true);
      try {
        await designsService.removeDesign(design.id);
        createNotification('warning', 'Design is removed');
        setDesigns(designs.filter(d => d.id !== design.id));
      } catch (error) {
        ////console.log(error);
      }
      setBLoading(false);
    }
  };
  useEffect(() => {
    user && loadData();
  }, [user]);

  return (
    <Wrapper>
      <LoadingTitle
        title="Saved designs"
        bLoading={bLoading}
        handleBack={handleBack}
      />
      <ResponsiveTable>
        <table className="designs__table">
          <tbody>
            <tr>
              <th>Thumbnail Front</th>
              <th>Category</th>
              <th>Title</th>
              <th>Actions</th>
            </tr>
            {designs
              .filter(design => design)
              .slice(page * ROWS_DESIGNS, page * ROWS_DESIGNS + ROWS_DESIGNS)
              .map((design, i) => (
                <tr key={`design-${i}`}>
                  <td>
                    <ItemPreviewCanvas
                      fabricJsonFront={design.fabricJsonFront}
                    />
                  </td>
                  <td>{design.categoryName}</td>
                  <td>{design.cardTitle}</td>
                  <td className="warning">
                    <Link
                      to={`${slugHelper.generateCardSlug(
                        design.categoryName,
                        design.cardTitle
                      )}?design_id=${design.id}`}
                    >
                      Load
                    </Link>
                    <TooltipIcon
                      icon={faTrash}
                      onClick={ev => handleRemove(design)}
                      title="Remove design"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ResponsiveTable>
      {designs.length > ROWS_DESIGNS && (
        <PaginationWrapper>
          <ReactPaginate
            previousLabel="Prev"
            nextLabel="Next"
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(designs.length / ROWS_DESIGNS)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

export default SavedDesigns;
