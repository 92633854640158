import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
// import core components
import { UserContext } from 'contexts';
import { usersService, cognitoService } from 'services';
import LoadingTitle from './LoadingTitle';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .accountDetailsBox {
    margin-right: 70px;
    margin-bottom: 20px;
    padding: 30px;
    background: ;
    box-shadow: 0px 4px 44px #0000000c;
    border-radius: 10px;
  }

  .form-title {
    margin-bottom: 15px;
    text-align: center;
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
  }

  .inputBox label {
    margin-bottom: 6px;
  }

  .inputBox input {
    padding: 15px;
    border: 1px solid ${({theme}) => theme.colors.stroke};
    border-radius: 10px;
    color: ${({theme}) => theme.colors.black};
    font-size: 14px;
  }

  /* Password Box Design */
  .passwordBox {
    margin-bottom: 20px;
    padding: 30px;
    background: ${({theme}) => theme.colors.white};
    box-shadow: 0px 4px 44px #0000000c;
    border-radius: 10px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
  }

  .inputBox label {
    margin-bottom: 6px;
  }

  .inputBox input {
    padding: 15px;
    border: 1px solid ${({theme}) => theme.colors.stroke};
    border-radius: 10px;
    color: ${({theme}) => theme.colors.black};
    font-size: 14px;
  }

  .active {
    background: var(--lightBlue);
  }
  
  .active-text {
    color: var(--black);
    font-weight: 600;
  }
  
  .button {
    height: 59px;
    width: 100%;
    text-align: center;
    background: ${({theme}) => theme.colors.blue};
    border: none;
    color: ${({theme}) => theme.colors.white};
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
  }
  
  .button:hover {
    cursor: pointer;
  }
  
  .warning {
    margin-bottom: 10px;
  }
  input[type="text"] {
    outline: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    .row {
      flex-direction: column;
      width: 100%;
      > form {
        margin: 0px;
        width: 100%;
        padding: 15px;
      }
    }
    .form-title {
      font-size: 14px;
    }
    label, input[type=text] {
      font-size: 14px;
    }
    input[type=text] {
      padding: 7px;
    }
    input[type=submit] {
      height: 30px;
    }
  }
`;

const UserSettings = ({ user, onUpdateUser, handleBack}) => {
  const { createNotification } = useContext(UserContext); // Context API to update user state
  const [newUser, setNewUser] = useState({firstName: user.firstName || "", lastName: user.lastName || ""});
  const [password, setPassword] = useState({current: "", new: ""});
  const [error, setError] = useState({user: "", pwd: ""});
  const [bLoading, setBLoading] = useState(false);
  const updateUser = async ev => {
    ev.preventDefault();
    setBLoading(true);
    setError({...error, user: ""});
    try {
      onUpdateUser(await usersService.updateUser({...user, ...newUser}));
      // setError({...error, user: "User updated"});
      createNotification('success', "User updated");
    } catch (error) {
      // setError({...error, user: error.message});
      createNotification('warning', error.message);
    }
    setBLoading(false);
  }
  const changePassword = async ev => {
    ev.preventDefault();
    setBLoading(true);
    setError({...error, pwd: ""});
    try {
      await cognitoService.changePassword(password.current, password.new);
      // setError({...error, pwd: "Password changed"});
      createNotification('success', "Password changed");
    } catch (error) {
      // setError({...error, pwd: error.message});
      createNotification('warning', error.message);
    }
    setBLoading(false);
  }
  return (
    <Wrapper>
      <LoadingTitle title="User Settings" bLoading={bLoading} handleBack={handleBack}/>
      <div className="row">
        <form className="accountDetailsBox" onSubmit={updateUser}>
          <h3 className="form-title">Account Details</h3>
          {error.user && <p className="error">{error.user}</p>}
          <div className="emailInput inputBox">
            <label htmlFor="email">Your email address</label>
            <input type="text" disabled placeholder={user.emailAddress} />
          </div>
          <div className="firstsNameInput inputBox">
            <label htmlFor="firstName">First Name</label>
            <input type="text" name="firstName" placeholder="Name" value={newUser.firstName} onChange={ev => setNewUser({...newUser, firstName: ev.target.value})}/>
          </div>
          <div className="lastNameInput inputBox">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" name="lastName" placeholder="Last Name" value={newUser.lastName} onChange={ev => setNewUser({...newUser, lastName: ev.target.value})}/>
          </div>

          <input type="submit" className="saveChangesBtn button" value="Update" />
        </form>
        <form className="passwordBox" onSubmit={changePassword}>
          <h3 className="form-title">Change password</h3>
          {error.pwd && <p className="error">{error.pwd}</p>}
          <div className="currentPassw inputBox">
            <label htmlFor="currentPassword">Current Password</label>
            <input required type="password" placeholder="••••••••••••••" value={password.current} onChange={ev => setPassword({...password, current: ev.target.value})}/>
          </div>

          <div className="newPassword inputBox">
            <label htmlFor="newPassword">New Password</label>
            <input required type="password" placeholder="••••••••••••••" value={password.new} onChange={ev => setPassword({...password, new: ev.target.value})}/>
          </div>

          {/* <div className="confirmNewPassword inputBox">
            <label htmlFor="confirmNewPassword">Confirm new password</label>
            <input type="password" placeholder="••••••••••••••" value={password.confirm} onChange={ev => setPassword({...password, confirm: ev.target.value})}/>
          </div> */}
          <input type="submit" className="chnagePassword button" value="Update" />
        </form>
      </div>
    </Wrapper>
  )
};

export default UserSettings;
