import styled from '@emotion/styled';
import LoadingGif from '/static/gifs/loading.gif';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
    h2,
    img {
        margin: 0px;
    }
    color: ${({theme}) => theme.colors.blue};
    .back-btn {
        display: none;
    }
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
        display: flex;
        align-items: center;
        padding-left: 20px;
        .back-btn {
            display: block;
            margin-right: 10px;
        }
        .myaccount-title {
            font-size: 16px;
        }
    }
`;
const LoadingTitle = ({title, bLoading, handleBack}) => (
    <Wrapper>
        <FontAwesomeIcon icon={faArrowLeft} className="back-btn" onClick={handleBack}/>
        <h2 className="myaccount-title">{title}</h2>
        {bLoading && 
            <img className="loading-gif medium" src={LoadingGif} alt="image loading" />
        }
    </Wrapper>
)

export default LoadingTitle;