import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
// import core components
import { myAccountConstants } from '../../../constants';
import { domHelper } from 'helpers';
import { StackedImage } from 'components';
import { Modal } from 'components/Modal';
import OrderDetail from './OrderDetail';
// import static icons and images
import ShoppingBag2Icon from '/static/icons/my-account/shopping-bag2.svg';
import User2Icon from '/static/icons/my-account/user2.svg';
import Book2Icon from '/static/icons/my-account/book2.svg';
import FolderIcon from '/static/icons/my-account/folder.svg';
import LogoutIcon from '/static/icons/my-account/logout.svg';
import CartIcon from '/static/icons/my-account/cart-icon.svg';
import LoadingGif from '/static/gifs/loading.gif';
import { toUTCDateTime } from 'utils/moment';
import ArrowRight from '../../../../static/icons/arrows/arrowRight.svg';
import ItemPreviewCanvas from '../../ItemPreviewCanvas';

const Wrapper = styled.div`
  li,
  p,
  img {
    margin: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0px 10px;
  }
  .myAccount__sideBar {
    min-height: 283px;
    width: 270px;
    margin-bottom: 30px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
    }
  }

  .myAccount__title {
    background: ${({ theme }) => theme.colors.blue};
    padding: 14px 22px;
    color: ${({ theme }) => theme.colors.white};
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 16px;
      padding: 10px 16px;
      text-align: center;
    }
  }

  .sideBar-items {
    list-style-type: none;
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    border-top: none;
    border-radius: 0 0 6px 6px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      border: none;
    }
  }

  .sideBar-items .item {
    display: flex;
    align-items: center;
    height: 45px;
    cursor: pointer;
    padding: 25px 0px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightBlue};
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      background: ${({ theme }) => theme.colors.gray};

      margin-bottom: 8px;
      border-radius: 16px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightBlue};
      }
    }
  }

  .sideBar-items .item:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  }

  .sideBar-items .item a {
    text-decoration: none;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
  }

  .lastOrder__sideBar {
    width: 270px;
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    border-radius: 10px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
    }
  }

  .lastOrder__title {
    padding: 24px 21px;
    background: ${({ theme }) => theme.colors.gray};
    border-radius: 10px 10px 0 0;
    font-weight: 600;
  }
  .lastOrder__body {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px 15px;
    min-height: 403px;
    .loading-gif {
      margin: auto;
    }
  }
  .lastOrder__informations {
    list-style-type: none;
    margin: 0px;
  }

  .lastOrder__informations li {
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .lastOrder__informations li .regularWeight {
    font-weight: 400;
    margin-left: 5px;
  }

  .lastOrder__informations li {
    margin-bottom: 15px;
  }

  /* Order Details Footer Small text */
  .lastOrder__details {
    display: flex;
    align-items: center;
    padding-left: 21px;
    margin-top: 13px;
    margin-bottom: 13px;
    color: ${({ theme }) => theme.colors.blue};

    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .lastOrder__details:hover {
    cursor: pointer;
  }

  .lastOrder__details .details-text {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blue};
    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .item {
    display: flex;
    align-items: center;
  }
  .icon-tilte {
    display: flex;
    align-items: center;
  }
  .arrow-right {
    display: none;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      display: block;
      margin-left: auto;
    }
  }

  ${StackedImage} {
    width: 150px;
  }
`;
const TAB_ITEMS = [
  { Icon: ShoppingBag2Icon, title: 'Orders', tabId: myAccountConstants.ORDERS },
  {
    Icon: Book2Icon,
    title: 'Address Book',
    tabId: myAccountConstants.ADDRESS_BOOK,
  },
  {
    Icon: FolderIcon,
    title: 'Saved Designs',
    tabId: myAccountConstants.SAVED_DESIGNS,
  },
  {
    Icon: User2Icon,
    title: 'User Settings',
    tabId: myAccountConstants.USER_SETTINGS,
  },
];
const TabItem = ({ Icon, title, tabId, setCurrentTab, currentTab }) => (
  <li
    className={`item ${currentTab === tabId ? 'active' : 'inactive'}`}
    onClick={() => setCurrentTab(tabId)}
  >
    <div className="icon-tilte">
      <Icon />
      <a>{title}</a>
    </div>
    <div className="arrow-right">
      <ArrowRight />
    </div>
  </li>
);
const Tab = props => {
  const { lastOrder } = props;
  const [bShowLastOrder, setBShowLastOrder] = useState(false);
  //console.log(lastOrder, 'last order');

  return (
    <Wrapper>
      <div className="myAccount__sideBar">
        <h3
          className="myAccount__title"
          onClick={() => props.setCurrentTab(myAccountConstants.DASHBOARD)}
        >
          My Account
        </h3>
        <ul className="sideBar-items">
          {TAB_ITEMS.map(tabItem => (
            <TabItem key={tabItem.title} {...tabItem} {...props} />
          ))}
          <li
            className="item signout"
            onClick={ev => domHelper.triggerClick('signout-button')}
          >
            <LogoutIcon />
            <a>Sign Out</a>
          </li>
        </ul>
      </div>
      <div className="lastOrder__sideBar">
        <p className="lastOrder__title">Last Order</p>
        <div className="lastOrder__body">
          {lastOrder ? (
            <>
              <ul className="lastOrder__informations">
                <li className="info">
                  Order #:
                  <p className="regularWeight">{lastOrder.orderId}</p>
                </li>
                <li className="info">
                  Date:
                  <p className="regularWeight">
                    {toUTCDateTime(lastOrder.creationDate, true)}
                  </p>
                </li>
                <li className="info">
                  Total:
                  <p className="regularWeight">{`$ ${lastOrder.orderTotal}`}</p>
                </li>
              </ul>
              {lastOrder.lineItems.length && (
                <ItemPreviewCanvas
                  fabricJsonFront={lastOrder.fabricJsonFront}
                />
              )}
              <div className="lastOrder__details">
                <CartIcon />
                {/* <Link className="details-text" to={`/my-account/order?id=${lastOrder.orderId}`}> */}
                <a
                  className="details-text"
                  onClick={ev => setBShowLastOrder(true)}
                >
                  Order details
                </a>
              </div>
            </>
          ) : lastOrder === undefined ? (
            <></>
          ) : (
            <img
              className="loading-gif large"
              src={LoadingGif}
              alt="image loading"
            />
          )}
        </div>
      </div>

      {/* Modal to show lineitems of an order */}
      {lastOrder && (
        <Modal
          isActive={bShowLastOrder}
          hideModal={() => setBShowLastOrder(false)}
        >
          <OrderDetail
            order={lastOrder}
            hideMore={() => setBShowLastOrder(false)}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default Tab;
