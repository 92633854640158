import CloseIcon from '/static/icons/controls/close.svg'
import { CloseModalButton } from 'components/Login/styles';
import LineItem from 'components/LineItem';
import styled from '@emotion/styled';
const LineItemsWrapper = styled.div`
  position: absolute;
  width: 80%;
  min-height: 350px;
  background: ${({theme}) => theme.colors.stroke};
  border-radius: ${({theme}) => theme.borderRadius.round};
`;
const StyledLineItems = styled.div`
  max-height: 800px;
  overflow-y: auto;
  padding: 70px 20px 20px 20px;
  > ul {
    list-style: none;
  }
`;

const OrderDetail = ({ hideMore, order }) => (
    <LineItemsWrapper>
      <CloseModalButton onClick={hideMore}>
        <CloseIcon />
      </CloseModalButton>
      <StyledLineItems>
        <ul>
          {order.lineItems.map(lineItem => <LineItem as="li" lineItem={lineItem} />)}
        </ul>
      </StyledLineItems>
    </LineItemsWrapper>
)

export default OrderDetail;