import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
// import core components
import LoadingTitle from './LoadingTitle';
import { ROWS_ADDRESS_BOOK } from '/config/pagination'
import ResponsiveTable from './ResponsiveTable';
import TooltipIcon from './TooltipIcon';
import { Modal } from 'components/Modal';
import Address from './Address';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddressTable = styled.table`
`;
const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    background-color: ${({theme}) => theme.colors.stroke};
    ul.pagination {
        display: inline-block;
        margin: 0px;
        margin-left: -5px;
    }
    ul.pagination li {
        cursor: pointer;
        cursor: pointer;
        padding: 3px;
        margin: 0px;
        margin-right: 5px;
        display: inline-block;
        color: ${({theme}) => theme.colors.black};
        &:hover {
            color: ${({theme}) => theme.colors.blue};
        }
        &.active {
            border: 1px solid ${({theme}) => theme.colors.blue};
            color: ${({theme}) => theme.colors.blue};
        }
        &.disabled {
            visibility: hidden;
        }
        > a {
            font-weight: 600 !important;
        }
    }
`;

const AddressBook = ({ addresses, bLoading, onRemove, handleBack}) => {
  /** Pagination */
  const [page, setPage] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState();

  const handlePageClick = (data) => {
    setPage(data.selected);
  };
  
  const toSafeField = (field, bLeft = false) => {
    return field ? `${field + (bLeft ? " " : "")}` : "";
  }
  const handleRemove = address => {
    if(window.confirm("Do you want to continue?"))
      onRemove(address.id)
  }
  const handleEdit = address => {
    setSelectedAddress(address);
  }
  const handleClose = ev => {
    setSelectedAddress(undefined);
  }
  return (
    <Wrapper>
      <LoadingTitle title="Address Book" bLoading={bLoading} handleBack={handleBack}/>
      <ResponsiveTable>
      <AddressTable>
        <tbody>
          <tr>
            <th>Full Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
          {addresses.filter(address => address.addressName).slice(page * ROWS_ADDRESS_BOOK, page * ROWS_ADDRESS_BOOK + ROWS_ADDRESS_BOOK).map((address, i) => 
            <tr key={`addreeline-${i}`}>
              <td>{address.addressName}</td>
              <td>
                {address.addressCompany && <p>{address.addressCompany}</p>}
                <p>{`${address.addressLine1} ${toSafeField(address.addressLine2)}`}</p>
                <p>{`${address.addressCity} ${toSafeField(address.addressStateProvice)}`}</p>
                <p>{`${toSafeField(address.addressPostcode, true)}${address.addressCountry}`}</p>
              </td>
              <td>{address.addressPhoneNumber}</td>
              <td className="warning">
                <TooltipIcon icon={faTrash} onClick={ev => handleRemove(address)} title="Remove address"/>
                <TooltipIcon icon={faPencilAlt} onClick={ev => handleEdit(address)} title="Edit address"/>
              </td>
            </tr>
          )}
        </tbody>
      </AddressTable>
      </ResponsiveTable>
      {addresses.length > ROWS_ADDRESS_BOOK && <PaginationWrapper>
        <ReactPaginate
            previousLabel='Prev'
            nextLabel='Next'
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(addresses.length / ROWS_ADDRESS_BOOK)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />
      </PaginationWrapper>}

      {selectedAddress && <Modal isActive={!!selectedAddress} hideModal={handleClose}>
        <Address initial={selectedAddress} hideModal={handleClose}/>
      </Modal>}
    </Wrapper>
  )
};

export default AddressBook;
