import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
// import core components
import Tab from './Tab';
import Dashboard from './Dashboard';
import Orders from './Orders';
import UserSettings from './UserSettings';
import SavedDesigns from './SavedDesigns';
import AddressBook from './AddressBook';

import { myAccountConstants } from '../../../constants';
import { UserContext } from 'contexts';
import { domHelper, localstorageHelper } from 'helpers';
import { ordersService, addressService } from 'services';
import { isMobile } from 'react-device-detect';
import * as fflate from 'fflate';
import axios from 'axios';
import { readTextFile } from 'utils/file';

const Wrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 90px);
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
  ul {
    margin-left: 0px;
  }
  p,
  img {
    margin-bottom: 0px;
  }
  > div:first-of-type {
    flex: 1;
  }
  > div:nth-of-type(2) {
    flex: 3;
    margin-left: 67px;
    height: 100%;
    padding-bottom: 1rem;
  }
  .active {
    background: ${({ theme }) => theme.colors.lightBlue};
    font-weight: 600;
  }
  .active-text {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
  }
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 25px;
  line-height: 38px;
  > span {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    margin: 0px;
  }
`;
const MyAccount = props => {
  const [currentTab, setCurrentTab] = useState(myAccountConstants.DASHBOARD);
  const { user, setUser, createNotification } = useContext(UserContext); // Context API to get user state
  const [addresses, setAddresses] = useState([]);
  const [orders, setOrders] = useState([]);
  const [bLoading, setBLoading] = useState(false);

  const handleBack = () => {
    setCurrentTab(myAccountConstants.DASHBOARD);
  };
  useEffect(() => {
    user && loadData();
  }, [user]);

  const loadData = async () => {
    setBLoading(true);
    try {
      // fetch addresses
      let getAddresses = await addressService.getAddresses(user.cognitoUserId);
      //console.log(getAddresses,"get addresses res")
      setAddresses(getAddresses || []);

      // fetch orders
      const ordersResp =
        (await ordersService.getOrders(user.cognitoUserId)) || [];
      // createNotification('success', "Orders are loaded");
      let ordersArray = ordersResp.sort(function(a, b) {
        return b.creationDate.localeCompare(a.creationDate);
      });
      for await (const order of ordersArray) {
        let getCanvasData = await axios.get(order.lineItems[0]?.url);
        if (getCanvasData.status === 200) {
          let getUrl = getCanvasData?.data?.fabricJsonFront;
          if (getUrl) {
            order.fabricJsonFront = getCanvasData?.data?.fabricJsonFront;
          }
        }
      }
      setOrders(ordersArray);
    } catch (error) {
      ////console.log(error);
      createNotification('warning', 'Network failed');
    }
    setBLoading(false);
  };
  /** handle when click remove address */
  const removeAddress = async id => {
    setBLoading(true);
    try {
      await addressService.removeAddress(id);
      setAddresses(addresses.filter(address => address.id !== id));
    } catch (error) {
      ////console.log(error);
    }
    setBLoading(false);
  };
  /** handle when click remove order */
  const handleRemoveOrder = async order => {
    if (window.confirm('Do you want to continue?')) {
      setBLoading(true);
      try {
        await ordersService.removeOrder(order.id);
        createNotification('warning', 'order is removed');
        setorders(orders.filter(d => d.id !== order.id));
      } catch (error) {
        ////console.log(error);
      }
      setBLoading(false);
    }
  };
  // Update user info
  const updateUser = newUserData => {
    localstorageHelper.saveUser(newUserData);
    setUser({ ...user, ...newUserData });
  };
  const switchTab = () => {
    switch (currentTab) {
      case myAccountConstants.ORDERS:
        return (
          <Orders
            orders={orders}
            bLoading={bLoading}
            handleBack={handleBack}
            handleRemoveOrder={handleRemoveOrder}
          />
        );
      case myAccountConstants.USER_SETTINGS:
        return (
          <UserSettings
            user={user}
            onUpdateUser={updateUser}
            handleBack={handleBack}
          />
        );
      case myAccountConstants.SAVED_DESIGNS:
        return <SavedDesigns user={user} handleBack={handleBack} />;
      case myAccountConstants.ADDRESS_BOOK:
        return (
          <AddressBook
            addresses={addresses}
            bLoading={bLoading}
            onRemove={removeAddress}
            handleBack={handleBack}
          />
        );
      default:
        return <Dashboard setCurrentTab={setCurrentTab} />;
    }
  };
  return (
    <Wrapper>
      {!user && (
        <Title>
          My Account
          <br />
          {!user && (
            <span className="warning">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;
              <a onClick={ev => domHelper.triggerClick('login-button')}>
                Signin
              </a>
              &nbsp;or&nbsp;
              <a onClick={ev => domHelper.triggerClick('register-button')}>
                register
              </a>{' '}
              to access this page
            </span>
          )}
        </Title>
      )}
      {isMobile ? (
        <>
          {user && (
            <>
              {currentTab === myAccountConstants.DASHBOARD ? (
                <Tab
                  setCurrentTab={setCurrentTab}
                  currentTab={currentTab}
                  lastOrder={bLoading ? null : orders[0]}
                />
              ) : (
                switchTab(currentTab, user, setCurrentTab)
              )}
            </>
          )}
        </>
      ) : (
        <>
          {user && (
            <>
              <Tab
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                lastOrder={bLoading ? null : orders[0]}
              />
              {switchTab(currentTab, user, setCurrentTab)}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default MyAccount;
