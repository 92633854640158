import React from 'react';

// import core components 
import { Layout, Container } from 'layouts';
import MyAccount from 'components/pages/my-account';

const MyAccountPage = props => {
  return (
    <Layout title="My Account">
        <Container>
          <MyAccount />
        </Container>
    </Layout>
  );
};

export default MyAccountPage;