import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import core components
import { Modal } from 'components/Modal';
import LoadingTitle from './LoadingTitle';
import { ROWS_ORDERS } from '/config/pagination';
import { toUTCDateTime } from 'utils/moment';
import ResponsiveTable from './ResponsiveTable';
import { StackedImage } from 'components';
import OrderDetail from './OrderDetail';
import TooltipIcon from './TooltipIcon';
import ItemPreviewCanvas from '../../ItemPreviewCanvas';
import { ordersService } from '../../../services';

const Wrapper = styled.div`
  table {
    td {
      height: 220px;
    }
    td.warning {
      a {
        margin-right: 10px;
      }
    }
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  background-color: ${({ theme }) => theme.colors.stroke};
  ul.pagination {
    display: inline-block;
    margin: 0px;
    margin-left: -5px;
  }
  ul.pagination li {
    cursor: pointer;
    cursor: pointer;
    padding: 3px;
    margin: 0px;
    margin-right: 5px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.black};
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
    &.active {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.blue};
    }
    &.disabled {
      visibility: hidden;
    }
    > a {
      font-weight: 600 !important;
    }
  }
`;

const Orders = ({ orders, bLoading, handleBack, handleRemoveOrder }) => {
  const [selectedOrder, setSelectedOrder] = useState(-1);
  /** Pagination */
  const [page, setPage] = useState(0);
  const handlePageClick = data => {
    setPage(data.selected);
  };
  const showMore = index => {
    setSelectedOrder(index);
  };
  const hideMore = () => {
    setSelectedOrder(-1);
  };

  return (
    <Wrapper>
      <LoadingTitle
        title="Orders"
        bLoading={bLoading}
        handleBack={handleBack}
      />
      <ResponsiveTable>
        <table className="orders__table">
          <thead>
            <tr>
              <th>Design</th>
              <th>Order #</th>
              <th>Date</th>
              {/* <th>Recipient</th> */}
              {/* <th>Address</th> */}
              <th>Total</th>
              {/*<th>Status</th>*/}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders
              .filter(order => order)
              .slice(page * ROWS_ORDERS, page * ROWS_ORDERS + ROWS_ORDERS)
              .map((order, i) => (
                <tr key={`order-${i}`}>
                  <td>
                    <ItemPreviewCanvas
                      fabricJsonFront={order.fabricJsonFront}
                    />
                  </td>
                  <td>{order.orderId}</td>
                  <td>{toUTCDateTime(order.creationDate)}</td>
                  {/* <td>{order.recipientFullName}</td> */}
                  {/* <td>{order.recipientStreetAddress}</td> */}
                  <td>{`SGD ${order.orderTotal}`}</td>
                  {/*<td>{order.status}</td> */}
                  <td className="warning">
                    <a onClick={ev => showMore(i)}>Show details</a>
                    {/*<TooltipIcon
                      icon={faTrash}
                      onClick={ev => handleRemoveOrder(order)}
                      title="Remove order"
                    />*/}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ResponsiveTable>
      {orders.length > ROWS_ORDERS && (
        <PaginationWrapper>
          <ReactPaginate
            previousLabel="Prev"
            nextLabel="Next"
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(orders.length / ROWS_ORDERS)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </PaginationWrapper>
      )}

      {/* Modal to show lineitems of an order */}
      {orders && orders[selectedOrder] && (
        <Modal isActive={selectedOrder >= 0} hideModal={hideMore}>
          <OrderDetail order={orders[selectedOrder]} hideMore={hideMore} />
        </Modal>
      )}
    </Wrapper>
  );
};

export default Orders;