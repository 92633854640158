
import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types"

const Wrapper = styled.span`
    margin-right: 10px;
`;

const TooltipIcon = ({icon, title, onClick}) => (
    <Wrapper>
        <a data-tip data-for={'icon-button-' + title}>
            <FontAwesomeIcon icon={icon} onClick={onClick}/>
        </a>
        <Tooltip id={'icon-button-' + title} place='top' effect='solid' className="icon-tooltip">
            <p>{title}</p>
        </Tooltip>
    </Wrapper>
)

TooltipIcon.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
}

export default TooltipIcon;